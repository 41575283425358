.container {
    width: 253px;
    height: 329px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 1px solid white;
}

.photoBlock {

}
.nameBlock {

}