.wrap {
    height: 635px;
    /* display: flex; */
    /* flex-direction: column; */
    /*flex-wrap: wrap;*/
    padding-left: 45px;
    /* align-items: flex-start; */
    /*justify-content: start;*/
}

.phoneBlock {
    margin-bottom: 18px;
}

.phone_number {
    width: 436px;
    height: 50px;
    padding-left: 20px;
    background: rgba(248, 248, 248, 0.2);
    border-radius: 10px;

    border: none;
}

.phone_number::placeholder {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    color: #D9D9D9;
}


.send_code_button {
    border: none;
    width: 471px;
    height: 58px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    background-color: #D9D9D9;
    border-radius: 10px;
    color: #000000;
}

.title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;
    /*padding: 30px 0 20px 0;*/
    color: #D9D9D9;
    margin-bottom: 20px;
}
.explanation {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    color: #D9D9D9;
    margin: 0 0 15px 0;
}


.question {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    padding: 0 0 16px 0;
    color: #D9D9D9;
}


.nav {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.nav a {
    padding-right: 20px;
}

.info {
    width: 481px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.025em;
    color: #D9D9D9;
    padding: 7px 0 22px 0;
}

.text {
    border: none;
    width: 439px;
    height: 160px;
    background: url("../../img/askQuestion/attachFile.svg") no-repeat  415px 120px #4F4F4F;;
    /*background: #4F4F4F;*/
    border-radius: 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    color: #D9D9D9;
    padding:  10px 0 0 16px;
    margin-bottom: 28px;
}

.text::placeholder {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    color: #D9D9D9;
}

