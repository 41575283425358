.container {
    background-color: #000000;
}

.block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;


}

.information {


}

.title  {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.025em;
    color: #D9D9D9;
}

.information {
    width:600px;
    text-align: left;
    margin : 30px 35px 65px 40px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.025em;
    color: #D9D9D9;
}

.information_handwriting {

    margin-top: 250px;
}

.delivery {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.025em;
    color: #D9D9D9;
}
@media (max-width: 1366px) {

    .information {
        margin: 35px 15px 10px 15px;;
        font-size: 16px;
    }

    .title  {
        font-size: 16px;
        line-height: 32px;
        letter-spacing: 0.025em;
        color: #D9D9D9;
    }
    .delivery {
        margin: 0 0 80px 0;
        font-size: 16px;
        text-align: left;
    }
    .information_handwriting {
        /*margin-bottom: 60px;*/
        font-size: 16px;
        margin: 250px 0 0 40px;
    }
    .information_handwriting p{
        margin: 5px 0;
    }
    .information_handwriting title{
        align-items: center;
    }



}

@media (max-width: 1000px) {
    .block{
        flex-wrap: wrap;
    }
    .delivery {
        margin: 0 0 0px 0;
        font-size: 16px;
        text-align: center;
    }
    .information_handwriting {
        margin: 0 0 45px 0;
    }
    .information_handwriting h3 {
        text-align: center;
    }

}
