.error_container {
    background-color: #000000;
}

.error_block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.error_title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 62px;
    color: #D9D9D9;

    text-align: left;

}

.mainButton {
    width: 270px;
    height: 50px;
    margin: 10px 0;
    background: #2E2E2E;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    color: #D9D9D9;

}
.information {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.info_text {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 36px;
    color: #D9D9D9;
    text-align: left;
    margin: 9px 0 75px 0;
}

.text_description {
    width: 450px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 36px;
    color: #D9D9D9;
    text-align: left;
    margin-top: 290px;
}

.error_text {
    width: 1324px;
    height: 900px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../img/error/error.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.info_text {
    margin: 9px 0 20px 0;
}
.text_description {
    margin-top: 290px;
}

.text {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 470px;
    line-height: 57px;
    color: rgba(255, 255, 255, 0.9);
    /*backdrop-filter: blur(3px);*/
}
.text_red {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 470px;
    line-height: 57px;
    color: #A70906;
}

@media (max-width: 1366px) {
    .error_text{
        width: 988px;
        height: 900px;
    }
    .text {
        font-size: 315px;
        line-height: 38px;
    }

    .text_red {
        font-size: 315px;
        line-height: 38px;
    }
    .text_description {
        margin-top: 250px;
    }
}

@media (max-width: 1000px) {
    .error_block {
        flex-wrap: wrap;
        background-image: url("../../img/error/1.png");
        background-repeat: no-repeat;
        background-size: cover;
    }
    .mainButton {
        width: 326px;
    }
    .text_description {
        display: none;
    }

    .error_text{
        width: 360px;
        height: 489px;
        background-image: none;
        margin-bottom: 40px;
    }
    .text {
        font-size: 165px;
        line-height: 20px;
    }

    .text_red {
        font-size: 165px;
        line-height: 20px;
    }
}