.nav {
    width: 580px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav a {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #9F9F9F;
    text-decoration: none;
}
.nav_message {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav_message a {


    padding: 0 22px 0 0;
}
.nav_phone {
    padding-left: 3px;
}

.burger {
    /*--offset-horizontal: 11px;*/
    /*--offset-vertical: 14px;*/
    /*position: relative;*/
    /*border-radius: 100%;*/
    /*padding: var(--offset-vertical) var(--offset-horizontal);*/
    /*width: 40px;*/
    /*height: 40px;*/
    border: none;
    background-color: #000000;
}

.burgerLine {
    position: absolute;
    left: 50%;
    top: var(--offset-vertical);
    display: inline-block;
    width: 32px;
    height: 2px;
    background-color: #D9D9D9;;
    transform: translateX(-50%);
}
.burgerLine:nth-child(2) {
    top: calc(var(--offset-vertical) + 6px);
}

.menuMedia {
    display: none;
}

.burgerLine:nth-child(3) {
    top: calc(var(--offset-vertical) + 12px);
}

.search {
    display: none;
}

@media (max-width: 1180px) {
    .nav_message a {
       padding: 0 15px;
    }
}

/*@media (max-width: 576px) {*/
/*   */
/*   */
/*}*/

@media (max-width: 820px) {
    .search {
        display: block;
    }
    .nav a{
        font-size: 14px;
    }
    .menu {
        display: none;
    }
    .menuMedia {
        display: block;
    }

    .phone_number_block {
        margin-right: 23px;
    }

    .nav_message {
        display: none;
    }


}
