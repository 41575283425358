.wrapper {
    width: 270px;
    margin: 0 95px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.buttonsBlock_title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 57px;
    text-align: left;
    color: #D9D9D9;
    margin: 31px 0;
}

.mainButton {
    width: 270px;
    height: 50px;
    margin: 10px 0;
    background: #2E2E2E;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    color: #D9D9D9;

}
@media (max-width: 1366px) {
    .wrapper {
        margin: 0 25px 0 0;

    }
}

@media screen  and (max-width: 732px) {
    .wrapper {
        width: 100%;
        justify-content: center;
    }
    .buttonsBlock_title {
        text-align: center;
        margin: 21px 0;
    }
}

