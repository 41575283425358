.contacts_container {
    background-color: #000000;
    min-height: 750px;
}

.basket_block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.025em;
    color: #D9D9D9;
}
.basket_table {
    min-width: 80%;
    color: #D9D9D9;
}
.table {
    margin: 110px 20px 100px 30px;
    border-collapse: collapse;
    border: 1px solid #D9D9D9;
    color: #D9D9D9;



}

 td, th {
    /*width: 1100px;*/
    margin: 20px 0;
    /*border-radius: 10px 10px 10px 10px;*/

    border: 1px solid #D9D9D9;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center

}
.table td {

    /*border: 1px solid #D9D9D9;*/
    padding: 15px 0;
}

.basketDeleteButton {
    outline: none;
    background: none;
    cursor: pointer;
    border: none;
}

.btn {
    cursor: pointer;
    border: none;
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #D9D9D9;
    background: rgba(0, 0, 0, 0.8);
}
.count {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #D9D9D9;
}
