.auth_wrapper {

    background-color: #000000;
    height: 778px;


}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    margin-top: 84px;
}

.wrap {
    width: 570px;
    height: 573px;
    background: #2E2E2E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(40px);
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    padding: 21px 20px 67px 46px;



}

.content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 46px;

}


.send_code_button {
    width: 483px;
    height: 60px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    background-color: #D9D9D9;
    border-radius: 10px;
}
.phone_number {
    width: 457px;
    height: 50px;
    padding-left: 20px;
    background: rgba(248, 248, 248, 0.2);
    border-radius: 10px
}

.buttons_position{
    margin: 0 auto;
}
.google_button {
    width: 230px;
    height: 58px;
    background: url("../../img/auth/google.png") no-repeat 25px #E5E5E5;

    padding-left: 45px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    border-radius: 10px;
    margin-right: 25px;
}
.facebook_button {
    width: 230px;
    height: 58px;
    background: url("../../img/auth/facebook.png") no-repeat 25px #E5E5E5;
    padding-left: 45px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    border-radius: 10px;
}

.a_for_email {
    margin: 0 auto;
}