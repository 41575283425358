.menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    /*right: 0;*/
    top: 0;
    transform: translateX(0);
    transition: all 0.2s;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 62px;
    color: #9F9F9F;
}



.menu__content {
    width: 25%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    /*align-items: flex-end;*/
    transition: all 0.2s;
    position: fixed;
    right: 0;
    backdrop-filter: blur(8px);
    /*padding-right: 10%;*/
}

.menu_header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 20%;
    padding-top: 12%;
    padding-bottom: 20%;
    /*width: 100%;*/
    text-align: center;
}

.menu_header div {
    margin-right: 25px;
}

.menu_content {
    display: flex;
    align-items: flex-start;
}
.content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 50px;
}



.line {
    height: 100%;
    border: 1px solid #9F9F9F;

}

.menu_info {
    padding:0 0 0 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-decoration: none;
}


.item {
    text-decoration: none;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 62px;
    color: #9F9F9F;
}
.menu_contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 60px;
    text-align: center;
    margin-top: 30px;
}
.menu_contacts_phone a {
    padding-left: 20px;
    text-decoration: none;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 62px;
    color: #9F9F9F;
}

.menu_messengers {
    display: flex;
    /*justify-content: space-around;*/
    /*align-items: flex-end;*/
    /*padding-left: 20px;*/
}

.menu_messengers div {
    padding: 0 10px;
}

.menu_messengers a {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;

    /*line-height: 27px;*/
    color: #9F9F9F;
    text-decoration: none;
    /*padding: 20px;*/

}






.menu_title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 62px;
    color: #D9D9D9;
    margin: 0;
    padding-bottom: 15px;
}

.menu_contacts h3 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 62px;
    color: #D9D9D9;
    text-align: left;
}

@media (max-width: 1670px) {
    .menu__content{
        width: 27%;
    }
}

@media (max-width: 1600px) {
    .menu__content{
        width: 30%;
    }

}

@media (max-width: 1331px) {
    .menu__content{
        width: 35%;
    }
    .menu_contacts_phone a {
        padding-left: 20px;
    }

}

@media (max-width: 1140px) {
    .menu__content{
        width: 40%;
    }

}

@media (max-width: 1000px) {
    .menu__content{
        width: 50%;
    }

}

@media (max-width: 800px) {
    .menu__content{
        width: 60%;
    }

}

@media (max-width: 665px) {
    .menu__content{
        width: 80%;
    }

}

@media (max-width: 500px) {
    .menu__content{
        width: 100%;
    }

    .menu_header {
        padding-right: 0;
        padding-top: 0;
    }

    .content {
        justify-content: center;
        /*align-items: flex-start;*/
        padding-left: 0px;
        text-align: center;

    }
    .line {
        display: none;
    }


    .menu_info {
        padding:0;
        align-items: center;
        text-decoration: none;
    }

    .menu_contacts {
        align-items: center;
        padding-right: 0px;
        text-align: center;
        margin-top: 0;
    }
    .menu_contacts h3{
        margin: 0;
    }

}

