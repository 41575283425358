.profile_container {

}

.profile_block {
    display: flex;
}

.wrapper {
    text-align: start;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    text-decoration: none;
    color: #D9D9D9;

}

.categories {
    padding: 0 0 15px 0;
    cursor: pointer;
}



.mainButton {
    width: 270px;
    height: 50px;
    margin: 10px 0;
    background: #2E2E2E;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    color: #D9D9D9;
}
.myData {
    padding: 20px  0 0 40px;
}