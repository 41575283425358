.header_container {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #000000;

}
.line {
    width: 87%;
    border: 1px solid #9F9F9F;
    margin: 0 auto;
}
.header_block {
    height: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header_logo {

}

.header_logo_for_media {
    display: none;
}
.nav span {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #9F9F9F;
}

.header_input_block {}
.inputForHeader {
    width: 555px;
    height: 50px;


    border-radius: 10px;

    background: url("../img/header/Search.png") no-repeat  25px #2E2E2E;
    padding-left: 57px;
    display: flex;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    border: none;
    color: #9F9F9F;

}

@media (max-width: 1560px) {
    .inputForHeader {
        width: 450px;
        background: url("../img/header/Search.png") no-repeat  20px #2E2E2E;
        padding-left: 45px;
    }
}

@media (max-width: 1450px) {
    .inputForHeader {
        width: 400px;
        background: url("../img/header/Search.png") no-repeat  10px #2E2E2E;
        padding-left: 35px;
    }
}

@media (max-width: 1280px) {
    .inputForHeader {
        width: 250px;
        background: url("../img/header/Search.png") no-repeat  5px #2E2E2E;
        padding-left: 30px;
    }
}

@media (max-width: 1180px) {
    .inputForHeader {
        width: 220px;
        background: url("../img/header/Search.png") no-repeat  5px #2E2E2E;
        padding-left: 27px;
    }
}

@media (max-width: 920px) {
    .inputForHeader {
        width: 220px;
        background: url("../img/header/Search.png") no-repeat  5px #2E2E2E;
        padding-left: 27px;
    }
}



@media (max-width: 820px) {

    .header_input_block {
        display: none;
    }
    .header_block {
        height: 50px;
    }

    .header_logo {
        display: none;

    }
    .header_logo_for_media {
        display: block;
    }
    .header_logo_block {
        margin-right: 13px;
    }

    .line {
        width: 100%;
    }
}



