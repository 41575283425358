.catalog_container {
    /*background-color: #000000;*/
    color: #D9D9D9;
    min-height: 780px;
}



.catalog_block {
    display: flex;
    justify-content: space-between;
    /*align-items: flex-start;*/




}
.wrapper {
    width: 270px;
    text-align: start;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    text-decoration: none;
    color: #D9D9D9;
    /*width: 14%;*/
    /*margin: 0 95px 0 0;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*flex-wrap: wrap;*/
    /*justify-content: flex-start;*/
}
.wrapper2 {
    display: flex;
    flex-wrap: wrap;
}

.categories {
    padding: 0 0 15px 0;
}


.mainButton {
    width: 270px;
    height: 50px;
    margin: 10px 0;
    background: #2E2E2E;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    color: #D9D9D9;

}

.catalog {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    margin-bottom: 80px;
}


.content_img {
    width: 25%;
}

.wrap  {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    text-decoration: none;
    color: #D10400;


}

.imageBlock {
    padding: 0 40px 0 40px;
}

.buttonForMedia {
    display: none;
}
.basket {
    /*align-self: center;*/

    padding: 30px 0 0 400px;

}

.price {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 40px;
    color: #D9D9D9
}

.basket img {
    width: 36px;
    height: 34px;
}

.spice img {
    width: 650px;
    height: 650px;
}
.infoBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 50px;
}


.infoBlock h3 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    /*line-height: 32px;*/
    letter-spacing: 0.025em;
    color: #D9D9D9;
    text-align: left;
}

.table {
    border-collapse: collapse;
    border-radius: 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center
}

.table td {
    border: 1px solid #2E2E2E;
    padding: 14px;
    color: #D9D9D9;

}

.text {

    margin-bottom: 20px;
    border-radius: 10px 10px 10px 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: left
}

.cardButton {
    width: 341px;
    height: 47px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #D9D9D9;
    background: #A70906;
    border-radius: 10px;

}

.cardInfo {
    width: 60%;
    padding: 31px 0 0 0;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #D9D9D9;
    text-align: left;
}

.btn {

    cursor: pointer;
    border: none;
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #D9D9D9;
    background: rgba(0, 0, 0, 0.8);
}

.count {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #D9D9D9;
}

.description {
    width: 596px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #D9D9D9;
    text-align: left;
}

.description h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
}


.composition {
    /*width: 596px;*/
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #D9D9D9;
    text-align: left;
    padding-bottom: 70px;
}
.composition h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
}
.actionCards{
    padding-left: 275px;
    padding-bottom: 105px;
    text-align: left;
}
.actionCard{
    display: flex;
}
.actionCard h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
}

@media (max-width: 1670px ) {
    .imageBlock {
        padding: 0;
    }
    .spice img {
        width: 600px;
        height: 600px;
    }
    .description {
        /*width: 100%;*/
    }
    .composition {
        /*width: 100%;*/
    }
    .wrapper2 {
        flex-wrap: nowrap;
    }
}
@media (max-width: 1455px ) {
    .spice img {
        width: 500px;
        height: 500px;
    }
}

@media (max-width: 1370px ) {


}

@media (max-width: 1240px) {
    .catalog_block {
        flex-wrap: wrap;
    }

    .spice img {
        width: 650px;
        height: 650px;
    }
}
@media (max-width: 840px) {
    .wrapper {
        display: none;
    }
    .buttonForMedia {
        display: block;
        background-color: #000000; ;
    }
    .buttonForMedia button {
        background-color: #000000;
        border: none;
        padding: 10px 320px 10px 0;
    }

    .buttonForMedia button:hover {

    }
    .description {
        font-size: 16px;
    }
    .composition {
        font-size: 16px;
    }

    .spice img {
        width: 350px;
        height: 350px;
    }
}
