.content_img {
    width: 33%;

    margin: 0 0px 40px 0px;

}
.card {

}
.basket {
    padding: 30px 0 0 150px;

}

.img_container {

}
.basket {
    position: absolute;
    text-align: right;
    padding: 30px 0 0 100px;
    z-index: 1;

}

.img_card {
    position: relative;
    width: 318px;
    height: 301px;
    cursor: pointer;

}

.titleCard {
    /*width: 282px;*/
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #D9D9D9;
}


.price {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #D10400;
}

@media (max-width: 1477px) {
    .img_card {
        width: 287px;
        height: 271px;
    }
}

@media (max-width: 1365px) {
    .content_img {
        width: 32%;
        padding-bottom: 20px;
    }

    .titleCard {
        width: 100%;
    }
}

@media (max-width: 1040px) {
    .content_img {
        width: 50%;
        margin: 0 0px 40px 0px;

    }

    .img_card {
        width: 156px;
        height: 147px;
    }
    .price {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }
}

@media (max-width: 840px) {
   .titleCard {
       font-size: 12px;
       line-height: 16px;
   }


}