.pagination_block {
    display: flex;
    justify-content: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #D9D9D9;
    padding-bottom: 40px;


}

.pagination_block_item {
    padding: 20px;
    color: #D9D9D9;

}


.pagination_block_item_active {
    padding: 20px;
    color: #A70906;

}

.btn {
    background-color: #000000;
    border: none;
}