
.main_container {
    /*height: 887px;*/
    background-color: #000000;
}

.main_block {
    /*height: 100%;*/
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;

}

.aboutUs {
    width: 270px;
    margin-right: 95px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.photo{
    width: 342px;
    text-align: left;
    margin-bottom: 51px;
}

.photo img {
    width: 100%;
    height: 100%;
    margin: 50px 0 20px 0 ;
}

.main_block p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.025em;
    color: #D9D9D9;
}

.photoBlockText {
    font-family: 'Open Sans', sans-serif;
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.025em;
    color: #D9D9D9;
    margin-bottom: 20px;
}
.information {
    width:600px;
    text-align: left;
    margin : 30px 0 0 40px;
}

.informationText {
    width: 600px;
}

.informationText p {
    margin: 25px 0;
}

.mainButton {
    width: 270px;
    height: 50px;
    margin: 10px 0;
    background: #2E2E2E;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    color: #D9D9D9;
}
.socialNetworkLink {
    display: flex;
}
.socialNetworkLink img {
     padding-right: 28px;
 }
.socialNetworkLink div {
    padding-right: 27px;
}

.socialNetworkLink div, .socialNetworkLink a  {
    text-decoration: none;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    color: #D9D9D9;
}
@media (max-width: 1477px) {
    .socialNetworkLink {
        margin-bottom: 50px;
    }
}

@media screen  and (max-width: 1365px) {
    .main_block {

    }

    .photo{

        text-align: left;
        /*margin-bottom: 51px;*/
    }

    .photo img {
        width: 270px;
        height: 383px;
        margin: 50px 0 15px 0 ;
    }

    .information {
        margin : 30px 0 0 25px;
    }
    .socialNetworkLink {
        margin-bottom: 50px;
    }
}

@media screen  and (max-width: 1180px) {
    .main_block {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
    }

    .photo{

        text-align: left;
        margin-bottom: 5px;
    }
    .information {
        margin : 0px 0 0 0px;
    }
}

@media screen  and (max-width: 732px) {
    .photo {
        display: flex;
        justify-content: space-between;
    }
    .photo img {
        width: 117px;
        height: 166px;
        margin: 0;
    }
    .information {
        margin: 0;
        padding: 0;
    }
    .informationText {
        width: 326px;
    }

    .informationText p {
        width: 326px;
    }

    .informationText1 {
        margin-top: 30px;
    }

    .socialNetworkLink {
        flex-direction: column;
    }

    .socialNetworkLink img {
        /*padding-right: 38px;*/

    }
    .socialLinkIcon {
        display: flex;
        justify-content: center;
        text-align: center;
        width: 360px;
    }


}

/*@media screen  and (max-width: 360px) {*/
/*    .socialLinkIcon {*/
/*        display: flex;*/
/*        justify-content: center;*/
/*        text-align: center;*/
/*    }*/
/*}*/
