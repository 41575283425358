/*.wrapper {*/
    /*width: 270px;*/
    /*margin: 0 95px 0 0;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*flex-wrap: wrap;*/
    /*justify-content: flex-start;*/
/*}*/

.wrapper {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    text-decoration: none;
    color: #D9D9D9;
}

div:checked  {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    text-decoration: none;
    color: #D10400;


}

.categories {
    padding: 0 0 15px 0;
    cursor: pointer;
}
