.footer_container {
    background-color: #000000;
}
.line {
    width: 87%;
    border: 1px solid #9F9F9F;
    margin: 0 auto;
}

.container {
    height: 100%;
    width: 87%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}


.footer_wrap {
    height: 271px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    text-decoration: none;
}


.footer_logo {
    padding: 49px 30px 0 0 ;
}

.footer_content{
    /*width: 1150px;*/
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.footer_content a {
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #9F9F9F;
}

.footer_info {
    margin: 48px 0px 31px 0;
    width: 100%;
    /*max-width: 1180px;*/
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #9F9F9F;
}

.workingOurs {}
.footer_block {
    margin: 0 0 31px 0;
    width: 100%;
    /*max-width: 1180px;*/
    display: flex;
    justify-content: flex-start;
    align-items: center;

}
footer_info span {
    margin: 0;
}
.footer_info div {
    margin-right: 25px;
    display: flex;
    align-items: center;
}

.footer_block div {
    margin-right: 30px;
}

.footer_block a {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #9F9F9F;
}

.footer_info span {
    margin-right: 5px;
}
.footer_block span {
    margin-right: 5px;
}



.searchInput {
    width: 342px;
    height: 36px;
    border-radius: 10px;
    background: url("../img/footer/Vector.png") no-repeat  15px #1D1D1D;
    padding-left: 47px;
    display: flex;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    border: none;
    color: #9F9F9F;
}

.footer_block_catalog {
    margin: 0 0 31px 0;
    width: 100%;
    /*max-width: 1180px;*/
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.footer_block_catalog div {
    margin-right: 40px;
}


@media (max-width: 1863px) {
    .searchInput {
        margin: 26px 0 0 0;
        width: 551px;
        height: 36px;
    }
}

@media (max-width: 1470px) {
    .footer_wrap {
        height: 350px;
    }
    .footer_block_catalog {
        text-align: left;
    }
}

@media (max-width: 1366px) {
    .footer_wrap {
        height: 370px;
    }
    .footer_info {
        justify-content: flex-start;
    }

    .workingOurs {
        width: 551px;
        text-align: left;
        margin: 26px 0 0 0;
    }
}

@media (max-width: 1139px) {
    .footer_block_catalog {
        display: none;
    }
}

@media (max-width: 820px) {

    .footer_logo {
        display: none;
    }

    .footer_wrap {
        height: 202px;
        /*flex-direction: column;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }
    .footer_info {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer_info, .footer_info a {
        font-size: 12px;
        line-height: 16px;
        justify-content: center;
        margin: 26px 0 0 0;
    }

    .footer_block {
        justify-content: center;
        margin: 20px 0;
    }
    .footer_info_email {
        margin-bottom: 15px;

    }


    .footer_info1 {
        display: none;
    }
    .footer_block1 {
        display: none;
    }
    .workingOurs {
        max-width: 360px;
        text-align: center;
        margin: 24px 0 0 0;
    }
    .line {
        width: 100%;
    }
}