.contacts_container {
    background-color: #000000;
}

.contacts_block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.025em;
    color: #D9D9D9;
}

.title {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.025em;
    color: #D9D9D9;
    text-align: left;

}

.requisites {

    margin: 25px 35px 73px 0;
    text-align: left;

}
.information_data{
    padding-bottom: 21px;
}

.payment_details p {
    margin: 0;
}

.information {
    text-align: left;
}

.information_text {
    width: 484px;
    margin: 59px 0 15px 0;
}
.mapImage1366 {
    display: none;
}
.mapImage360 {
    display: none;
}



.informationIcon img {
    margin: 0 15px;
}

.informationPhone {
    padding: 18px 0;
}

.informationPhone a, .informationEmail a{
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.025em;
    color: #D9D9D9;


}
.informationEmail {
    padding-bottom: 26px;
}
@media (max-width: 1550px) {
    .mapImage {
      display: none;
    }
    .mapImage1366 {
        display: block;
    }

}
@media (max-width: 1366px) {
    .requisites {
        margin: 25px 15px 73px 0;


    }
}

@media (max-width: 1130px) {
    .contacts_block {
        flex-wrap: wrap;
    }
}

@media (max-width: 728px) {
    .contacts_block {
        font-size: 16px;
    }

    .requisites {

        margin: 0;

    }
    .information_text {
        width: 400px;
        margin: 15px 0 15px 0;
    }
    .mapImage1366 {
        display: none;
    }
    .mapImage360 {
        display: block;
    }
    .map {
        margin: 20px 0 54px 0;
        text-align: center;
    }

}