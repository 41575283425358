.App {

  text-align: center;
  background-color: #000000;
  /*max-width: 1920px;*/
  /*padding: 0 15px;*/
  /*margin: 0 auto;*/

}




