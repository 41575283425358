.container{
    height: 100%;
    width: 87%;
    margin: 0 auto;

    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: flex-start;*/
}

@media (max-width: 840px) {
    /*.container {*/
    /*    width: 100%;*/
    /*}*/

}