.wrap {
    display: flex;
    flex-direction: column;
    /*flex-wrap: wrap;*/
    align-items: flex-start;
    width: 524px;
    height: 420px;
    padding-left: 46px;
    justify-content: flex-start;
}

/*.phone_number {*/
/*    width: 457px;*/
/*    height: 50px;*/
/*    padding-left: 20px;*/
/*    background: rgba(248, 248, 248, 0.2);*/
/*    border-radius: 10px*/
/*}*/

.send_code_button {
    width: 483px;
    height: 60px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    background-color: #D9D9D9;
    border-radius: 10px;
    border: none;
}

.title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;
    padding: 0px 0 20px 0;
    color: #D9D9D9;
}

.question {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    padding: 0 0 16px 0;
    color: #D9D9D9;
}
.nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.nav a {
    padding-right: 19px;
}
.phoneBlock {
    margin-bottom: 18px;
}
.phone_number {
    width: 461px;
    height: 50px;
    padding-left: 20px;
    background: rgba(248, 248, 248, 0.2);
    border-radius: 10px;

    border: none;
}

.phone_number::placeholder {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    color: #D9D9D9;
}



.info {
    width: 481px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.025em;
    color: #D9D9D9;
    padding: 7px 0 22px 0;
}
