.background {

  width:100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  /*opacity: 0.3;*/
  /*z-index: 1;*/
}

.modal {
  /*width: 570px;*/
  /*height: 670px;*/
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
  padding: 0 20px;
  text-align: left;
  background-color: #2E2E2E;
  border-radius: 5px;
  box-shadow: 0 27px 27px 0 rgba(0,0,0,0.2), 0 27px 27px 0 rgba(0,0,0,0.19);
  backdrop-filter: blur(20px);
}

.modal div:first-child {
  /*margin-top: 22px;*/
}

.modal div {
  /*font-weight: bold;*/
}

.escape {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 22px 21px 0 0;
}

.escape div {
  cursor: pointer;
  padding: 2px 7px;
  margin: 0;
  border-radius: 13px;
}

.modal input {
  margin-top: 0;
  margin-bottom: -25px;
}

.modal input::placeholder{
  color: white;
  opacity: 70%;
}

.modal label {
  margin: -15px 0 -15px 0;
  padding: 0;
}

.closeButton {
  cursor: pointer;
}
