.mainButtonBlock {}


.mainButton {
    width: 270px;
    height: 50px;
    margin: 0 0 25px 0;
    background: #2E2E2E;
    border: none;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    color: #D9D9D9;

}
.mainButton:hover {
    background: #9F9F9F;
    color: #2E2E2E;
}


@media screen  and (max-width: 732px) {
    .mainButtonBlock {
        display: none;
    }
}