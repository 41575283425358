.typeBarBlock {


}

.wrapper {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    text-decoration: none;
    color: #D9D9D9;
}

.catalog_title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
}
.wrapActive  {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    text-decoration: none;
    color: #D10400;
}
.wrap {
    font-weight: 400;
    color: #D9D9D9;
}


.categories {
    padding: 0 0 25px 0;
    cursor: pointer;
}

@media (max-width: 840px) {
    /*.catalog_title {*/
    /*    text-align: center;*/
    /*}*/

    .typeBarBlock {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }


}
