.catalog_container {
    /*background-color: #000000;*/

}



.catalog_block {
    display: flex;
    /*justify-content: flex-start;*/
    /*align-items: flex-start;*/



}
.wrapper {
    width: 270px;
    text-align: start;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    text-decoration: none;
    color: #D9D9D9;
}

.categories {
   padding: 0 0 15px 0;
    cursor: pointer;
}




.mainButton {
    width: 270px;
    height: 50px;
    margin: 10px 0;
    background: #2E2E2E;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    color: #D9D9D9;

}

.catalog {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
    margin-bottom: 80px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #D9D9D9;

}


.content_img {
    width: 33%;
}

.wrap  {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    text-decoration: none;
    color: #D10400;
}

@media (max-width: 1000px) {
    .catalog_block{
        justify-content: center;
    }
}

@media (max-width: 840px) {


    .catalog_block {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
    }

    .wrapper {
        text-align: center;
    }
    .catalog {
        justify-content: center;
    }
}


